import React from "react";
import { PageHeading } from "../../../Shared/Headings/PageHeading";
import { SectionHeading } from "../../../Shared/Headings/SectionHeading";
import { usePricingPage } from "../../contexts/PricingPageProvider";
import { PricingImportantInformationCard } from "./components/PricingImportantInformationCard";

export interface PricingRequiredInformationProps {
  className?: string;
}

export const PricingRequiredInformation = (
  props: PricingRequiredInformationProps
) => {
  const { ImportantPricingInformation } = usePricingPage();
  return (
    <section>
      <SectionHeading className="">
        Potrebné informácie
      </SectionHeading>
      <div className="cards grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-24 mt-8 lg:mt-14">
        {ImportantPricingInformation.map((information, index) => (
          <PricingImportantInformationCard
            key={index}
            number={index + 1}
            title={information.title}
            text={information.content}
          />
        ))}
      </div>
    </section>
  );
};
