import { useStaticQuery, graphql } from "gatsby";
export const getPricingPage = () => {
  const { strapiPricingPage } = useStaticQuery(graphql`
    query PricingPageQuery {
      strapiPricingPage {
        MainPrices {
          notice
          Price {
            price
            numberOfChildren
            title
          }
        }
        ImportantPricingInformation {
          title
          content
        }
        cancelationFees
        paymentRequirements
      }
    }
  `);

  return strapiPricingPage;
};
