import React from "react";
import ReactMarkdown from "react-markdown";
import { CtaButton } from "../../../Shared/CtaButton/CtaButton";
import { usePricingPage } from "../../contexts/PricingPageProvider";
import { MainPricesCards } from "./components/MainPricesCards";

export interface MainPricesProps {
  className?: string;
}

export const MainPrices = (props: MainPricesProps) => {
  const {
    MainPrices: { notice },
  } = usePricingPage();
  return (
    <section className={`mt-0 md:mt-18 xl:mt-32`}>
      <MainPricesCards />
      <ReactMarkdown
        escapeHtml={false}
        source={notice}
        className="notice mx-auto w-5/6 md:w-3/5 mt-16 mb-10 text-center text-lg text-gray-600"
      ></ReactMarkdown>
      <div className="btn-wrapper flex justify-center">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://monzun.auksys.com/master/index.php"
        >
          <CtaButton>Prihlásiť sa do klubu</CtaButton>
        </a>
      </div>
    </section>
  );
};
