import React from "react";
import { SectionHeading } from "../../../Shared/Headings/SectionHeading";
import { PricingCard } from "../../shared/PricingCard/PricingCard";
import GiftSvg from "./assets/gift.svg";
import PoolSvg from "./assets/pool.svg";

export interface OtherPricesProps {
  className?: string;
}

export const OtherPrices = (props: OtherPricesProps) => {
  const { className = "" } = props;
  return (
    <section className={``}>
      <SectionHeading>Ostatné ceny</SectionHeading>
      <div className=" lg:w-full w-11/12 mx-auto mt-8 lg:mt-10 cards grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-20">
        <PricingCard
          title="Plaváreň Štiavničky"
          price={
            <div className="text-3xl flex w-full justify-evenly">
              <div>5 lekcií</div>
              <div>50€/mes</div>
            </div>
          }
          icon={
            <div className="h-10 w-10">
              <img src={PoolSvg} alt="" />
            </div>
          }
        />
        <PricingCard
          title="Darčeková poukážka"
          price={
            <div className="text-base text-center text-md">
              Je možné ju zakúpiť v ľubovoľnej hodnote. Zvyšnú čiastku do ceny
              kurzu je možné uhradiť v hotovosti.
            </div>
          }
          icon={
            <div className="h-10 w-10">
              <img src={GiftSvg} alt="" />
            </div>
          }
        />
      </div>
    </section>
  );
};
