import React from "react";
import ReactMarkdown from "react-markdown";
import { ContentRenderer } from "../../../../Shared/ContentRenderer/ContentRenderer";

export interface OtherPricingInformationProps {
  className?: string;
  title: string;
  content: string;
}

export const OtherPricingInformation = (
  props: OtherPricingInformationProps
) => {
  const { title, content } = props;
  return (
    <div className={``}>
      <h3 className="text-primary text-3xl mb-8 font-semibold">{title}</h3>
      <ContentRenderer source={content} />
    </div>
  );
};
