import React, { createContext, ReactNode, useContext } from "react"
import { PricingPage } from "../../../lib/model/PricingPage"
import { getPricingPage } from "../hooks/getPricingPage"

const PricingPageContext = createContext<PricingPage.PricingPage | null>(null)

interface ResumeProviderProps {
  children: ReactNode
}

export const usePricingPage = (): PricingPage.PricingPage => {
  const pricingPage = useContext(PricingPageContext)
  if (pricingPage === null) throw new Error("No pricing page provided")
  return pricingPage
}

export const PricingPageProvider = ({ children }: ResumeProviderProps) => {
  const pricingPage = getPricingPage();
  
  return <PricingPageContext.Provider value={pricingPage}>{children}</PricingPageContext.Provider>
}