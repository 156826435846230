import { styled } from "linaria/react";
import React from "react";
import ReactMarkdown from "react-markdown";
import { theme } from "../../../../Shared/Theme/Theme";

export interface PricingImportantInformationCardProps {
  className?: string;
  title: string;
  text: string;
  number: number;
}

const Number = styled.div`
  /* position: absolute; */
  z-index: -1;
  font-size: 72px;
  /* top: -60px; */
  /* left: -20px; */
  font-weight: 600;
  filter: opacity(0.3);
  background: ${theme.color.primaryGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const PricingImportantInformationCard = (
  props: PricingImportantInformationCardProps
) => {
  const { title, text, number } = props;
  return (
    <div className={`relative`}>
      <header className="flex items-center">
        <Number>{number}</Number>
        <h3 className="text-primary text-2xl mb-0 pl-6 font-semibold">
          {title}
        </h3>
      </header>
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
};
