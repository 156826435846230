import React from "react";
import { usePricingPage } from "../../../contexts/PricingPageProvider";
import { PricingCard } from "../../../shared/PricingCard/PricingCard";
import ChildSvg from "../assets/Child.svg";


export interface MainPricesCardsProps {
  className?: string;
}

const gradients = [
  `linear-gradient(139.53deg, #FFFA8D -19.18%, rgba(224, 88, 12, 0.9) 95.33%)`,
  `linear-gradient(147.59deg, #40E2F8 -15.25%, rgba(0, 47, 167, 0.87) 126.89%)`,
  `linear-gradient(134.55deg, #7DF196 24.81%, #0EB629 99.47%)`,
];

export const MainPricesCards = (props: MainPricesCardsProps) => {
  const { MainPrices } = usePricingPage();

  return (
    <div className=" w-10/12 md:w-full mx-auto price-cards grid grid-cols-1 md:grid-cols-3 gap-6 xl:gap-20">
      {MainPrices.Price.map((price, index) => (
        <PricingCard
          key={price.title}
          title={price.title}
          price={price.price}
          gradient={gradients[index]}
          icon={
            <div className="space-x-0 flex">
              {Array.from(Array(price.numberOfChildren).keys()).map(
                (_, index) => (
                  <img
                    className="h-10 w-10"
                    key={index}
                    src={ChildSvg}
                    alt="Child icon"
                  />
                )
              )}
            </div>
          }
        />
      ))}
    </div>
  );
};
