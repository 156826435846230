import { styled } from "linaria/react";
import React, { ReactNode } from "react";
import { theme } from "../../../Shared/Theme/Theme";

export interface PricingCardProps {
  className?: string;
  icon: ReactNode;
  title: string | ReactNode;
  price: string | ReactNode;
  gradient?: string;
}

const UnderLine = styled.div<{ gradient: string }>`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 7px;
  background: ${(props) => props.gradient};
`;

export const PricingCard = (props: PricingCardProps) => {
  const {
    title = "",
    gradient = theme.color.primaryGradient,
    icon,
    price,
  } = props;

  return (
    <article
      className={`rounded-xl shadow-xl p-8 relative overflow-hidden bg-white flex flex-col`}
    >
      <header className="flex justify-between">
        <div className="title font-light text-2xl flex items-center">
          {title}
        </div>
        <div className="icon">{icon}</div>
      </header>
      <main className="flex justify-center mt-10 mb-7 flex-auto items-center">
        <div
          className="price w-full text-center text-4xl font-medium"
        >
          {price}
        </div>
      </main>
      <UnderLine gradient={gradient}></UnderLine>
    </article>
  );
};
