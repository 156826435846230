import { styled } from "linaria/react";
import React from "react";
import PricingWvaveSvg from './assets/PricingWvaveSvg.svg'

export interface ContactWaveProps {
  className?: string;
}

const Wave = styled.img`
  width: 100%;
  position: absolute;
  top: 100px;
  z-index: -1;
  display: none;
  object-fit: contain;
  filter: opacity(0.3);

  @media (min-width: 1280px) {
    display: flex;
  }
`;

export const PricingWave = (props: ContactWaveProps) => {
  return <Wave src={PricingWvaveSvg} alt="" />;
};
