import React from "react";
import { usePricingPage } from "../../contexts/PricingPageProvider";
import { OtherPricingInformation } from "./components/OtherPricingInformation";

export interface PricingOtherInformationProps {
  className?: string;
}

export const PricingOtherInformation = (
  props: PricingOtherInformationProps
) => {
  const { cancelationFees, paymentRequirements } = usePricingPage();
  return (
    <section className={`grid grid-cols-1 lg:grid-cols-2 gap-24`}>
      <OtherPricingInformation
        title={"Storno poplatky"}
        content={cancelationFees}
      />
      <OtherPricingInformation
        title={"Platobné podmienky"}
        content={paymentRequirements}
      />
    </section>
  );
};
