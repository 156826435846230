import { styled } from "linaria/react";
import { theme } from "../Theme/Theme";

export const SectionHeading = styled.h2`
  font-size: 32px;
  color: ${theme.color.primary};
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 40px;
  }
`;

