import React from "react";
import { PricingPageProvider } from "../components/Pricing/contexts/PricingPageProvider";
import { PricingWave } from "../components/Pricing/PricingWave/PricingWave";
import { MainPrices } from "../components/Pricing/sections/MainPrices/MainPrices";
import { OtherPrices } from "../components/Pricing/sections/OtherPrices/OtherPrices";
import { PricingOtherInformation } from "../components/Pricing/sections/PricingOtherInformation/PricingOtherInformation";
import { PricingRequiredInformation } from "../components/Pricing/sections/PricingRequiredInformation/PricingRequiredInformation";
import { Container } from "../components/Shared/Container/Container";
import { PageHeading } from "../components/Shared/Headings/PageHeading";
import Layout from "../components/Shared/Layout/Layout";
import { SectionsWrapper } from "../components/Shared/SectionsWrapper/SectionsWrapper";
import { SEO } from "../components/Shared/SEO/SEO";

const PricingPage = () => (
  <Layout>
    <SEO title={"Cenník"} />
    <PricingPageProvider>
      <Container>
        <PageHeading>Cenník</PageHeading>
        <SectionsWrapper>
          <MainPrices />
          <PricingRequiredInformation />
          <OtherPrices />
          <PricingOtherInformation />
        </SectionsWrapper>
      </Container>
      <PricingWave />
    </PricingPageProvider>
  </Layout>
);

export default PricingPage;
